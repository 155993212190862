// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
// 👇️ Endpoints
import { urlVesselVoyageService } from '@src/endpoints'
// 👇️ Utils
import { getUriParams } from '@utils'
import { ToastContent } from "@src/views/components/toast/ToastContent"
import toast from 'react-hot-toast'

const ToastMessage = (heading, message, isSuccess) => {
  toast(t => (
    <ToastContent t={t} heading={heading} response={isSuccess} message={message} />
  ))
}


//function to remove all null, undefined, empty string from object else make the value caps
function clean(obj) {
  const cleanedObj = {}
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      continue
    } else {
      cleanedObj[propName] = obj[propName].toUpperCase()
    }
  }
  return Object.assign({}, obj, cleanedObj)
}

export const getData = createAsyncThunk('vesselList/getAllVesselVoyageByTerminal', async data => {
  const { params, filterForm } = data
  const uri = getUriParams(params)
  const response = await axios.post(`${urlVesselVoyageService}/GetAllVessel?${uri}`, { ...clean(filterForm), id: "" } || { id: "" })
  return {
    params,
    terminal: response.data.data.terminal === '' ? {} : response.data.data.terminal,
    data: response.data.data || [],
    totalPages: response.data.totalCount || 0,
    loaded: true,
    filterForm
  }
})

export const addVessel = createAsyncThunk('vesselList/addVessel', async (data, { dispatch }) => {
  const { vesselData, params, filterForm } = data
  await axios.post(`${urlVesselVoyageService}/AddVessel`, { ...vesselData, id: "" })
  await dispatch(getData({ params, filterForm }))
  return vesselData
})

export const deleteVessel = createAsyncThunk('vesselList/deleteVessel', async (data, { dispatch }) => {
  const { id, params, filterForm } = data
  try {
    const response = await axios.delete(`${urlVesselVoyageService}/DeleteVessel/${id}`)
    response?.data?.success ? toast.success(response.data.messages) : toast.error(response.data.errors)
    await dispatch(getData({ params, filterForm }))
  } catch (error) {
    ToastMessage("Error", error.message, false)
  }
})

export const vesselSlice = createSlice({
  name: 'vesselList',
  initialState: {
    loaded: false,
    loadedAll: false,
    loadedFFR: false,
    data: [],
    terminal: {},
    total: 1,
    params: {},
    filterForm: {},
    allData: {},
    status: [],
    notes: [],
    allTimeSheetUser: [],
    allContacts: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.terminal = action.payload.terminal
        state.filterForm = action.payload.filterForm
      })
      .addCase(getData.rejected, (state) => {
        state.loaded = true
        state.data = []
        state.params = {}
        state.total = 0
      })
      //anything pending will set loaded to false
      .addMatcher(action => action.type.endsWith('/pending'), (state) => {
        state.loaded = false
      })
      //reset loaded to true if rejected
      .addMatcher(action => action.type.endsWith('/rejected'), (state) => {
        state.loaded = true
      })

  }
})

export default vesselSlice.reducer