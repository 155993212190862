// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'
import { urlEquipmentService, urlReportingService } from '../../../../endpoints'
import { getUriParams } from '@utils'

export const getScheduleTerminal = createAsyncThunk('customerDashboard/getScheduleTerminal', async (data) => {
    const { params, filters } = data

    const uri = getUriParams(params)
    const filterData = {
        movementType: "schedule", // Logistic Dashboard
        customerName: filters.customerName === "ALL" || filters.customerName === "" ? "" : filters.customerName || "",
        // line: filters.line || "",
        equipmentNumber: filters.equipmentNumber || "",
        commodityType: filters.commodityType || "",
        cargoType: filters.cargoType || "",
        commodity: filters.commodity || "",
        ...(filters.tracingStatus !== 'TRANSIT' ? { [filters.tracingStatus]: filters.tracingDate } : {})
        }
        const response = await axios.post(`${urlEquipmentService}/GetEquipmentByMovementType?${uri}`, filterData)
    return {
        params,
        data: response.data.data,
        totalPages: response.data.totalCount,
        filterData
    }
})

export const getAllInboundCargo = createAsyncThunk('logisticDashboard/getAllInboundCargo', async (data) => {
    const { params, filters } = data

    const uri = getUriParams(params)
    const filterData = {
        equipmentNumber: filters.equipmentNumber || ""
    }
    const response = await axios.post(`${urlReportingService}/GetAllInboundCargo?${uri}`, filterData)
    return {
        params,
        data: response.data.data,
        totalPages: response.data.totalCount,
        filterData
    }
})

export const logisticDashboardSlice = createSlice({
    name: 'logisticDashboard',
    initialState : {
        data: [],
        params: {},
        total: 0,
        filter: {},
        loaded: false
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getScheduleTerminal.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
            state.filter = action.payload.filterData
            state.loaded = true
        })
        builder.addCase(getAllInboundCargo.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
            state.filter = action.payload.filterData
            state.loaded = true
        })
        builder.addMatcher(
            action => {
                return action.type.includes('/pending')
            },
            (state) => {
                state.loaded = false
            }
        )
    }
})

export default logisticDashboardSlice.reducer